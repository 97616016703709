import React from 'react';

import Separator from '@/dls/Separator/Separator';

const Footer: React.FC = () => {
  return (
    <div>
      <Separator />
    </div>
  );
};

export default Footer;
