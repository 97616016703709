/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, RefObject, useCallback, useRef } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import SearchDrawerHeader from './Header';

import Drawer, { DrawerType } from '@/components/Navbar/Drawer';
import Spinner from '@/dls/Spinner/Spinner';
import useDebounce from '@/hooks/useDebounce';
import useFocus from '@/hooks/useFocusElement';
import { selectNavbar } from '@/redux/slices/navbar';
import { selectSelectedTranslations } from '@/redux/slices/QuranReader/translations';
import { addSearchHistoryRecord } from '@/redux/slices/Search/search';
import { selectIsSearchDrawerVoiceFlowStarted } from '@/redux/slices/voiceSearch';
import AvailableLanguage from '@/types/AvailableLanguage';
import AvailableTranslation from '@/types/AvailableTranslation';
import ChaptersData from '@/types/ChaptersData';
import { SearchMode } from '@/types/Search/SearchRequestParams';
import SearchService from '@/types/Search/SearchService';
import SearchQuerySource from '@/types/SearchQuerySource';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { areArraysEqual } from '@/utils/array';
import {
  logButtonClick,
  logEmptySearchResults,
  logSearchResults,
  logTextSearchQuery,
} from '@/utils/eventLogger';
import { getDefaultTranslationIdsByLang } from '@/utils/search';
import {
  getAvailableLanguages,
  getAvailableTranslations,
  getNewSearchResults,
  getSearchResults,
} from 'src/api';
import { SearchResponse } from 'types/ApiResponses';
import { GetStaticProps } from 'next';
import { getAllChaptersData } from '@/utils/chapter';

const SearchBodyContainer = dynamic(() => import('@/components/Search/SearchBodyContainer'), {
  ssr: false,
  loading: () => <Spinner />,
});
const VoiceSearchBodyContainer = dynamic(
  () => import('@/components/TarteelVoiceSearch/BodyContainer'),
  {
    ssr: false,
    loading: () => <Spinner />,
  },
);

const DEBOUNCING_PERIOD_MS = 1000;

type SearchProps = {
  languages: AvailableLanguage[];
  translations: AvailableTranslation[];
  chaptersData: ChaptersData;
};

const SearchDrawer: React.FC<SearchProps> = ({ translations }) => {
  const { lang } = useTranslation('common');
  const [selectedTranslations, setSelectedTranslations] = useState<string>(() => {
    return getDefaultTranslationIdsByLang(translations, lang) as string;
  });
  const [focusInput, searchInputRef]: [() => void, RefObject<HTMLInputElement>] = useFocus();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const isOpen = useSelector(selectNavbar, shallowEqual).isSearchDrawerOpen;
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResponse>(null);
  const isVoiceSearchFlowStarted = useSelector(selectIsSearchDrawerVoiceFlowStarted, shallowEqual);
  const [selectedLanguages, setSelectedLanguages] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const router = useRouter();
  // Debounce search query to avoid having to call the API on every type. The API will be called once the user stops typing.
  const debouncedSearchQuery = useDebounce<string>(searchQuery, DEBOUNCING_PERIOD_MS);
  // once the drawer is open, focus the input field

  useEffect(() => {
    if (isOpen) {
      focusInput();
    }
  }, [isOpen, focusInput]);

  useEffect(() => {
    if (router.query.q || router.query.query) {
      let query = router.query.q as string;
      if (router.query.query) {
        query = router.query.query as string;
      }
      setSearchQuery(query);
    }

    if (router.query.page) {
      setCurrentPage(Number(router.query.page));
    }
    if (router.query.languages) {
      setSelectedLanguages(router.query.languages as string);
    }
    if (router.query.translations) {
      setSelectedTranslations(router.query.translations as string);
    }
  }, [
    router.query.q,
    router.query.query,
    router.query.page,
    router.query.languages,
    router.query.translations,
  ]);

  useEffect(() => {
    setSelectedTranslations(getDefaultTranslationIdsByLang(translations, lang) as string);
  }, [lang, translations]);

  const getResults = useCallback(
    (query: string, page: number, translation: string, language: string) => {
      setIsSearching(true);
      logTextSearchQuery(query, SearchQuerySource.SearchPage);
      getSearchResults({
        query,
        filterLanguages: language,
        size: 10,
        page,
        ...(translation && { filterTranslations: translation }), // translations will be included only when there is a selected translation
      })
        .then(async (response) => {
          if (response.status === 500) {
            setHasError(true);
          } else {
            setSearchResult({ ...response, service: SearchService.QDC });
            const noQdcResults =
              response.pagination.totalRecords === 0 && !response.result.navigation.length;
            // if there is no navigations nor verses in the response
            if (noQdcResults) {
              logEmptySearchResults({
                query,
                source: SearchQuerySource.SearchPage,
                service: SearchService.QDC,
              });
              const kalimatResponse = await getNewSearchResults({
                mode: SearchMode.Advanced,
                query,
                size: 10,
                filterLanguages: language,
                page,
                exactMatchesOnly: 0,
                filterTranslations:
                  lang === 'id'
                    ? '134,141,33'
                    : lang === 'en'
                    ? '85,131,84,95,19,22,20,203,57'
                    : lang === 'ms'
                    ? '39'
                    : null,
                translationFields: 'resource_name',
              });
              setSearchResult({
                ...kalimatResponse,
                service: SearchService.KALIMAT,
              });
              if (kalimatResponse.pagination.totalRecords === 0) {
                logEmptySearchResults({
                  query,
                  source: SearchQuerySource.SearchPage,
                  service: SearchService.KALIMAT,
                });
              } else {
                logSearchResults({
                  query,
                  source: SearchQuerySource.SearchPage,
                  service: SearchService.KALIMAT,
                });
              }
            }
          }
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setIsSearching(false);
        });
    },
    [lang],
  );

  const isInitialSearch = useRef(true);

  // This useEffect is triggered when the debouncedSearchQuery value changes
  // eslint-disable-next-line react-func/max-lines-per-function
  useEffect(() => {
    // only when the search query has a value we call the API.
    if (debouncedSearchQuery) {
      dispatch({ type: addSearchHistoryRecord.type, payload: debouncedSearchQuery });
      logTextSearchQuery(debouncedSearchQuery, SearchQuerySource.SearchDrawer);
      setIsSearching(true);
      getResults(
        debouncedSearchQuery,
        // if it is the initial search request, use the page number in the url, otherwise, reset it
        isInitialSearch.current ? currentPage : 1,
        selectedTranslations,
        selectedLanguages,
      );
    } else {
      // reset the result
      setSearchResult(null);
    }
  }, [
    debouncedSearchQuery,
    getResults,
    selectedLanguages,
    selectedTranslations,
    dispatch,
    currentPage,
  ]);

  const resetQueryAndResults = () => {
    logButtonClick('search_drawer_clear_input');
    // reset the search query
    setSearchQuery('');
    // reset the result
    setSearchResult(null);
    // reset the error
    setHasError(false);
  };

  /**
   * Handle when the search query is changed.
   *
   * @param {React.FormEvent<HTMLInputElement>} event
   * @returns {void}
   */
  const onSearchQueryChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const newSearchQuery = event.currentTarget.value;
    if (!newSearchQuery) {
      resetQueryAndResults();
    } else {
      setSearchQuery(newSearchQuery);
    }
  };

  /**
   * When the keyword is clicked, we move the cursor to the end of
   * the input field after setting its value.
   *
   * @param {string} keyword
   */
  const onSearchKeywordClicked = (keyword: string) => {
    const end = keyword.length;
    setSearchQuery(keyword);
    searchInputRef.current.setSelectionRange(end, end);
    focusInput();
  };

  return (
    <Drawer
      hideCloseButton={isVoiceSearchFlowStarted}
      type={DrawerType.Search}
      header={
        <SearchDrawerHeader
          isVoiceFlowStarted={isVoiceSearchFlowStarted}
          onSearchQueryChange={onSearchQueryChange}
          resetQueryAndResults={resetQueryAndResults}
          inputRef={searchInputRef}
          isSearching={isSearching}
          searchQuery={searchQuery}
        />
      }
    >
      <div>
        {isOpen && (
          <>
            {isVoiceSearchFlowStarted ? (
              <VoiceSearchBodyContainer />
            ) : (
              <SearchBodyContainer
                onSearchResultClicked={() => searchInputRef?.current?.blur()}
                onSearchKeywordClicked={onSearchKeywordClicked}
                searchQuery={searchQuery}
                searchResult={searchResult}
                isSearching={isSearching}
                hasError={hasError}
              />
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default SearchDrawer;
