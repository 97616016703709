import BottomSection from './BottomSection';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer>
      <div className={styles.flowItem}>
        <BottomSection />
      </div>
      <div className={styles.emptySpacePlaceholder} />
    </footer>
  );
};

export default Footer;
