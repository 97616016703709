import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import styles from './NavbarLogoWrapper.module.scss';

import Link from '@/dls/Link/Link';
import { getBlurDataUrl } from '@/utils/image';

const NavbarLogoWrapper = () => {
  const { t } = useTranslation('common');
  return (
    <Link href="/" className={styles.logoWrapper} title={t('quran-com')}>
      <Image
        src="/images/logo/homeLogo.svg"
        layout="fixed"
        width={30}
        height={30}
        placeholder="blur"
        blurDataURL={getBlurDataUrl(32, 32)}
        alt="Logo"
      />
    </Link>
  );
};

export default NavbarLogoWrapper;
